// Import the node package
const XLSX = require("xlsx")

// Define and export the function
export default function sheetToJson(sheet) {
  // The object to return
  const json = []

  // Get the sheet's row and columns range
  const range = XLSX.utils.decode_range(sheet['!ref'])

  // To store the header values
  const headers = []

  // Loop through all the columns in first row
  for (let col = range.s.c; col <= range.e.c; col++) {
    // Get the header cell address
    const headerCellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: col })

    // Get the cell using its address
    const headerCell = sheet[headerCellAddress]

    // Check if the cell exists
    if (headerCell && headerCell.v !== undefined) {
      // Push it to the cells list
      headers[col] = String(headerCell.w || headerCell.v).trim()
    }
  }

  // Loop through all the rows, skipping first one
  for (let row = range.s.r + 1; row <= range.e.r; row++) {
    // To store the row data
    const rowData = {}

    // Loop through each column in this row
    for (let col = range.s.c; col <= range.e.c; col++) {
      // Get the cell address
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
      // Get the cell using its address
      const cell = sheet[cellAddress]

      // Append this value to the row
      rowData[headers[col]] = (cell && cell.v) ? String(cell.w || cell.v).trim() : null
    }

    // Add the row in the final json
    json.push(rowData)
  }

  // Return the json
  return json
}
