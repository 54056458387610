<template>
  <div>
    <!-- Show the form dialog -->
    <v-dialog :value="value" @input="(v) => $emit('input', v)" :persistent="isMakingRequest" max-width="540" scrollable>
      <!-- The file input for stories list -->
      <input
        type="file"
        name="crm-add-stories-file"
        accept=".csv, .xlsx, .xls"
        class="d-none"
        ref="fileUploadInput"
        @change="handleFileChange"
      />

      <v-card>
        <v-card-title class="primary pb-4">
          <div class="d-flex flex-wrap justify-space-between align-center width-100 white--text">
            <!-- Show the text -->
            <div>Add Story</div>

            <div class="d-flex align-center">
              <!-- Show the button to download example sheet -->
              <v-btn depressed class="mr-3" color="primary" :disabled="isMakingRequest" @click="downloadSampleSheet">
                <v-icon left> download </v-icon>

                Sample
              </v-btn>

              <!-- Show the button to upload in bulk -->
              <v-btn
                depressed
                color="white primary--text"
                :disabled="isMakingRequest"
                :loading="isUploading"
                @click="$refs.fileUploadInput && $refs.fileUploadInput.click()"
              >
                <v-icon left> file_upload </v-icon>

                Upload XLSX
              </v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <story-form :overview="overview" :is-making-request="isCreating" @submit="handleFormSubmit" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show the confirmation dialog -->
    <v-dialog v-model="shouldShowConfirmationDialog" max-width="500">
      <v-card>
        <v-card-title class="primary white--text"> There were invalid rows in the file </v-card-title>

        <v-card-text class="pt-6">
          There were some invalid rows in the file you uploaded, do you want to continue with the valid rows?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="primary" @click="doHideDialogs"> Cancel </v-btn>

          <v-btn depressed color="primary" @click="uploadStories"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node package
const CSV = require("csv")
const XLSX = require("xlsx")

// Import helper functions
import sheetToJson from "@/helpers/sheetToJson"

// Import children components
const StoryForm = () => import(/* webpackChunkName: "crm-story-form" */ "@/components/crm/StoryForm.vue")

// Export the SFC
export default {
  // Name of the component
  name: "AddStory",

  // Register children components
  components: {
    StoryForm
  },

  // Accept incoming data from parent
  props: {
    // The entire campaign object
    overview: {
      type: Object,
      required: true
    },

    // Whether or not to show the dialog
    value: {
      type: Boolean,
      required: true
    },

    // The Vuex store action to call
    module: {
      type: String,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is a network request being made
    isMakingRequest: false,
    isCreating: false,

    // Whether or not is the form uploading
    isUploading: false,
    // The input items to be uploaded
    inputItems: [],
    // Whether or not to show the confirmation dialog when there are invalid items
    shouldShowConfirmationDialog: false,

    // Module and API endpoint map
    moduleMap: {
      campaignTracking: "campaign-tracking",
      influencerOutreach: "influencer-outreach"
    },

    // To be populated dynamically
    influencers: []
  }),

  // Define local method functions
  methods: {
    /**
     * Hide all the dialogs
     *
     * @returns {void}
     */
    doHideDialogs() {
      // Hide the confirmation dialog
      this.shouldShowConfirmationDialog = false

      // Hide the form dialog
      this.$emit("input", false)
    },

    /**
     * Make network request to upload the stories
     *
     * @returns {void}
     */
    async uploadStories() {
      // Make the dialog persistent and show a loader
      this.isUploading = true
      this.isMakingRequest = true

      // Hide the confirmation dialog
      this.shouldShowConfirmationDialog = false

      // Otherwise if it's all right
      try {
        // Use a helper function
        const response = await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/stories/bulk`,
          method: "POST",
          data: {
            stories: this.inputItems.map((item) => ({
              ...item,
              posted_at: dayjs(`${item.date} ${item.time}`).format("YYYY-MM-DD HH:mm:ss")
            }))
          }
        })

        // If received a response, go through each item
        for (const story of response.data) {
          // Push it to the store
          this.$store.dispatch(`${this.module}/updateStory`, { id: this.overview.model.id, story })
        }

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Stories added to the campaign!" })

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshStories", { detail: this.overview.model.id }))
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "CRM/AddStory Bulk Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error?.response?.data?.error?.message || "An error occurred, please try again" })
      } finally {
        // Nonetheless
        // Hide the dialog
        this.$emit("input", false)

        // Hide the loader
        this.isUploading = false

        // Make dialog non persistent
        this.isMakingRequest = false
      }
    },

    /**
     * Validate and upload all the story rows
     *
     * @param {Array} items
     * @returns {void}
     */
    validateStories(items) {
      // If there are no valid rows
      if (items.length === 0) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No valid rows found, please check the file." })

        // End the execution
        return
      }
      // Otherwise, if there are more than 10,000 rows
      else if (items.length > 10_000) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "You cannot add more than 10k stories at once" })

        // End the execution
        return
      }

      // To store all the valid rows
      const rows = []

      // The function to parse the key names
      const convert = (object) =>
        Object.fromEntries(Object.entries(object).map((item) => [item[0].trim().toLowerCase().replace(" ", "_"), item[1]]))

      // Keep a track of iterations
      let counter = 0
      let invalidItems = 0

      // Go through all the values
      for (const item of items) {
        // Increment the counter
        counter++

        // If all the values are null or empty
        if (Object.values(item).filter((_) => _).length === 0) {
          // Skip this row completely
          continue
        }

        // Get the converted item
        const converted = convert(item)

        // Compute the row object
        const row = {
          username: typeof converted.username === "string" ? converted.username.trim() : null,

          date: typeof converted.date === "string" ? converted.date.trim() : null,
          time: typeof converted.time === "string" ? converted.time.trim() : "00:00",

          reach: Number(converted.reach) || 0,
          impressions: Number(converted.impressions) || 0,

          shares: Number(converted.shares) || 0,
          replies: Number(converted.shares) || 0,
          reactions: Number(converted.reactions) || 0,

          sticker_taps: Number(converted.sticker_taps) || 0,
          website_clicks: Number(converted.website_clicks) || 0
        }

        // Keep track of errors
        let errors = 0

        // If it's missing username
        if (!row.username) {
          // Increment the counter
          errors++

          // Push a notification
          this.$store.dispatch("notifications/add", {
            text: `Username missing in row ${counter}!`,
            type: "error",
            icon: "alternate_email",
            event: {
              module: "crm-stories",
              type: "validation",
              key: "error-username"
            }
          })
        }
        // Otherwise, if username exists
        // else {
        //   // Check if the influencer is not in campaign
        //   if (!this.overview.influencers.find((search) => search.username === row.username)) {
        //     // Increment the counter
        //     errors++

        //     // Push a notification
        //     this.$store.dispatch("notifications/add", {
        //       text: `${row.username} is not present in the campaign influencers list, please check.`,
        //       type: "error",
        //       icon: "person",
        //       event: {
        //         module: "crm-stories",
        //         type: "validation",
        //         key: "error-missing-user"
        //       }
        //     })
        //   }
        // }

        // If it's missing date
        if (!row.date) {
          // Increment the counter
          errors++

          // Push a notification
          this.$store.dispatch("notifications/add", {
            text: `Date missing in row ${counter}!`,
            type: "error",
            icon: "event",
            event: {
              module: "crm-stories",
              type: "validation",
              key: "error-date"
            }
          })
        }
        // Otherwise, if date exists
        else {
          // Check if the date does not fall in the range
          if (!dayjs(row.date).isBetween(this.overview.model.start_date, this.overview.model.end_date, "day", "[]")) {
            // Increment the counter
            errors++

            // Push a notification
            this.$store.dispatch("notifications/add", {
              text: `Story for ${row.username} does not fall in the date range of the campaign (${row.date}), on row ${counter}`,
              type: "error",
              icon: "date_range",
              event: {
                module: "crm-stories",
                type: "validation",
                key: "error-invalid-date"
              }
            })
          }
        }

        // The stat values, if any of them are less than 0
        const statMap = ["reach", "impressions", "shares", "replies", "reactions", "sticker_taps", "website_clicks"]

        // For each of these
        for (const key of statMap) {
          // If it's missing the stat value or if it's less than 0
          if ([undefined, null].includes(row[key]) || row[key] < 0) {
            // Increment the counter
            errors++

            // Push a notification
            this.$store.dispatch("notifications/add", {
              text: `Invalid value for ${key.replace("_", " ")} in row ${counter}`,
              type: "warning",
              icon: "dangerous",
              event: {
                module: "crm-stories",
                type: "validation",
                key: "error-" + key
              }
            })
          }
        }

        // If there are no errors
        if (errors === 0) {
          // Push this item in the list
          rows.push(row)
        }
        // Otherwise, increment the counter
        else {
          invalidItems++
        }
      }

      // Check if there are no rows
      if (rows.length === 0) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "There are no valid stories in the file you selected." })

        // End further execution
        return
      }

      // Set the input items
      this.inputItems = rows

      // If there are invalid items
      if (invalidItems) {
        // Show a dialog to confirm action
        this.shouldShowConfirmationDialog = true
      }
      // Otherwise
      else {
        // Make network request
        this.uploadStories()
      }
    },

    /**
     * Handle the file input change for bulk stories upload
     *
     * @returns {void}
     */
    handleFileChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })

        // End the execution
        return
      }

      // Get the file object
      const file = event.target.files[0]

      // Validate the mime type of this file is CSV
      if (
        !["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)
      ) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "File is not a CSV or XLSX" })

        // End the execution
        return
      }

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isUploading = true

      // Define a function to hide the loaders
      const doHideLoaders = () => {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isUploading = false
      }

      // Otherwise, try to read the file
      const reader = new FileReader()

      // Add a callback function on it
      reader.addEventListener("load", async () => {
        // If the file type is CSV
        if (file.type === "text/csv") {
          // Try parsing the text as a CSV document
          CSV.parse(reader.result, async (error, result) => {
            // If there's any error
            if (error) {
              // Log the error
              logger({ type: "CSV Parse Error", error })

              // Show an error message
              this.$store.dispatch("toasts/add", { text: "File is not a valid CSV document" })

              // Also hide the loaders
              doHideLoaders()

              // End the execution
              return
            }
            // Otherwise
            else {
              // To store all the result items
              const headers = []
              const items = []

              // Go through each row
              for (const [rowIndex, row] of result.entries()) {
                // Prepare an item object
                const item = {}

                // Go through each column
                for (const [colIndex, column] of row.entries()) {
                  // If the column is empty
                  if (!column) {
                    continue
                  }
                  // Get the value
                  const value = column.trim()

                  // If it's the header row
                  if (rowIndex === 0) {
                    headers.push(value)
                  }
                  // Otherwise
                  else {
                    // Populate the value in item
                    item[headers[colIndex]] = value || null
                  }
                }

                // If not the header row
                if (rowIndex > 0) {
                  // Push the item object
                  items.push(item)
                }
              }

              // Hide the loaders
              doHideLoaders()

              // Make network request
              this.validateStories(items)
            }
          })
        }
        // Otherwise
        else {
          const buffer = reader.result

          // Load the file as XLSX
          const workbook = XLSX.read(buffer)
          // Translate the XLSX into a JSON object
          const items = sheetToJson(workbook.Sheets[workbook.SheetNames[0]])

          // Hide the loaders
          doHideLoaders()

          // Make network request
          this.validateStories(items)
        }
      })

      // If the file is of type CSV
      if (file.type === "text/csv") {
        // Read the file as a text
        reader.readAsText(file)
      }
      // Otherwise
      else {
        // Read the file as array buffer
        reader.readAsArrayBuffer(file)
      }
    },

    /**
     * Generate and download a sample XLSX sheet file
     *
     * @returns {void}
     */
    downloadSampleSheet() {
      // To disable the buttons
      this.isMakingRequest = true

      // Initiate a workbook instance
      const workbook = XLSX.utils.book_new()

      // Create the worksheet
      const worksheet = XLSX.utils.json_to_sheet([])

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet)

      // Add headers
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          "Username",
          "Date",
          // "Time",
          "Reach",
          "Impressions",
          "Replies",
          "Reactions",
          "Shares",
          "Sticker Taps",
          "Website Clicks"
        ]
      ])

      // Style with width
      worksheet["!cols"] = Array(10).fill({ wch: 16 })

      // Download this instance
      XLSX.writeFile(workbook, `Campaign Stories Sample Sheet - ${this.host.name}.xlsx`)

      // Enable the buttons again
      this.isMakingRequest = false
    },

    /**
     * Handle the create submission request
     *
     * @param {Object} formData
     * @returns {void}
     */
    async handleFormSubmit(formData) {
      // Show a loader
      const loaderId = Symbol()
      this.isCreating = true
      this.isMakingRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a network request
      try {
        // Use helper function to make the request
        const response = await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/stories`,
          method: "POST",
          data: formData
        })

        // If received a response, push it to the store
        this.$store.dispatch(`${this.module}/updateStory`, { id: this.overview.model.id, story: response.data })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Story added to the campaign!" })

        // Dispatch an event to refresh the values
        window.dispatchEvent(new CustomEvent("campaignTracking:refreshStories", { detail: this.overview.model.id }))
      } catch (error) {
        // Catch any error
        // Use global logger helper function
        logger({ type: "CRM/AddStory Error", error })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: error?.response?.data?.error?.message || "An error occurred, please try again" })
      } finally {
        // Nonetheless
        // Hide the loader
        this.isCreating = false
        this.isMakingRequest = false
        this.$store.dispatch("loaders/remove", loaderId)

        // Also hide the dialog
        this.$emit("input", false)
      }
    }
  }
}
</script>
